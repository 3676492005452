<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.$rtm.setBase(this);
  },
};
</script>

<style>
a {
  color: black;
  text-decoration: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.wrap {
  width: 100rem;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  background-color: azure;
}
</style>

<style lang="less">
html {
  font-size: 10px;
  height: 100%;
}
.pc {
  display: block;
}
.mobile {
  display: none;
}
.desktop-present {
  display: block !important;
}
.mobile-present {
  display: none !important;
}
@media all and (max-width: 800px) {
  html {
    font-size: 0.81vw !important;
  }
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
  .desktop-present {
    display: none !important;
  }

  .mobile-present {
    display: block !important;
  }
}

@media all and (max-width: 1200px) and (min-width: 800px) {
  html {
    font-size: 0.81vw !important;
    .pc {
      display: block;
    }
    .mobile {
      display: none;
    }
    .desktop-present {
      display: block !important;
    }

    .mobile-present {
      display: none !important;
    }
  }
}
</style>

</style>
