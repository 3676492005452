/* update time = 2024-05-20 12:28:44 */
import axios from 'axios'
const qs = require('qs')
var prefixURL = ''
var loadingStack = 0
function get(url, params, loading = true) {
    var config = {
        method: 'GET',
        url: url
    }
    if (params) {
        config.params = params
    }
    return execute(config, loading)
}
function post(url, params, loading = true) {
    var config = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: url
    }
    if (params) {
        config.data = qs.stringify(params)
    } else {
        config.data = ''
    }
    return execute(config, loading)
}
function put(url, params, loading = true) {
    var config = {
        method: 'PUT',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: url
    }
    if (params) {
        config.data = qs.stringify(params)
    } else {
        config.data = ''
    }
    return execute(config, loading)
}
function del(url, params, loading = true) {
    var config = {
        method: 'DELETE',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: url
    }
    if (params) {
        config.data = qs.stringify(params)
    }
    return execute(config, loading)
}
function execute(config, loading) {
    /* 正式部署需要删除 -- */
    // config.headers = {
    //     "rd-wx-cors-pass": "bcShdq8bUqJtC4rD"
    // }
    /* 正式部署需要删除 -- end */
    var tempSilence = silence
    silence = false
    config.withCredentials = true
    return new Promise((resolve, reject) => {
        if (loading && !tempSilence && loadingStartAction) {
            loadingStack++
            loadingStartAction()
            if (loadingTimer) {
                clearTimeout(loadingTimer)
            }
        }
        axios(config).then((response) => {
            if (response.data.code === 0) {
                resolve(response.data.data)
            } else {
                if (tempSilence) {
                    reject(response.data)
                } else {
                    if (errorHandler) {
                        var errorResult = errorHandler(response.data)
                        if (isPromise(errorResult)) {
                            errorResult.catch(() => {
                                reject(response.data)
                            })
                        } else {
                            if (!errorHandler) {
                                reject(response.data)
                            }
                        }
                    } else {
                        reject(response.data)
                    }
                }
            }
        }).catch((error) => {
            var errorObject = {
                code: -100,
                msg: "通信错误",
                data: error
            }
            if (tempSilence) {
                reject(errorObject)
            } else {
                if (errorHandler) {
                    var errorResult = errorHandler(errorObject)
                    if (isPromise(errorResult)) {
                        errorResult.catch(() => {
                            reject(errorObject)
                        })
                    } else {
                        if (!errorHandler) {
                            reject(errorObject)
                        }
                    }
                } else {
                    reject(errorObject)
                }
            }
        }).finally(() => {
            if (loading && !tempSilence && loadingEndAction) {
                loadingStack--
                loadingTimer = setTimeout(() => {
                    if (loadingStack <= 0) {
                        loadingEndAction()
                    }
                }, 200);
            }
        })
    })
}
function postFile(url, params, mime) {
    var config = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: url,
        withCredentials: true,
        responseType: 'blob'
    }
    if (params) {
        config.data = qs.stringify(params)
    } else {
        config.data = ''
    }
    loadingStartAction()
    if (loadingTimer) {
        clearTimeout(loadingTimer)
    }
    axios(config).then((response) => {
        var fileName = decodeURI(response.headers['content-disposition'].split(';')[1])
        var blob = new Blob([response.data], { type: mime })
        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName.match(/"(\S*)"/)[1])
        } else {
            var a = document.createElement('a')
            a.download = fileName.match(/"(\S*)"/)[1]
            a.href = window.URL.createObjectURL(blob)
            a.click()
        }
    }).finally(() => {
        loadingTimer = setTimeout(() => {
            loadingEndAction()
        }, 200);

    })
}
function isPromise(obj) {
    return !!obj
        && (typeof obj === 'object' || typeof obj === 'function')
        && typeof obj.then === 'function';
}
function ifNumber(obj) {
    return obj == undefined ? true : !isNaN(parseInt(obj))
}
var errorHandler
var loadingStartAction
var loadingEndAction
var loadingTimer
var silence = false
var RD = {
    pure(noloading) {
        if (noloading === undefined || noloading === true) {
            silence = true
        }
        return this
    },
    setPreFix(preFix) {
        prefixURL = preFix
    },
    setErrorHandler(cb) {
        errorHandler = cb
    },
    setLoadingStartAction(sa) {
        loadingStartAction = sa
    },
    setLoadingEndAction(ea) {
        loadingEndAction = ea
    },

    /* 主页 */
    index() {
        var url = prefixURL + '/index'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('index resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 获取主页缓存
            * @Param _type*[int]
            */
            main(_type) { return post(url + '/main', { type: _type }); },
            /**
            * 获取友情链接缓存
            */
            links() { return get(url + '/links'); },
            /**
            * 获取导航缓存
            */
            visit() { return get(url + '/visit'); },
            /**
            * 获取导航缓存
            */
            statistics() { return get(url + '/statistics'); },
            /**
            * 获取特定主页缓存
            * @Param _configId[int]
            */
            mainPreview(_configId) { return post(url + '/mainPreview', { configId: _configId }); },
            /**
            * 以catalog名称获取子栏目缓存
            * @Param _catalog[string]
            */
            catalogByName(_catalog) { return post(url + '/catalogByName', { catalog: _catalog }); },
            /**
            * 获取子栏目缓存
            * @Param _catalogId[int]
            */
            catalog(_catalogId) { return get(url + '/catalog' + '/' + _catalogId); },
        }
    },
    /* 用户，测试用入口 */
    user_newu() {
        var url = prefixURL + '/user_newu'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('user_newu resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * @Param _newspaper[string]
            * @Param _website[string]
            * @Param _wechat[string]
            * @Param _app[string]
            * @Param _magzine[string]
            * @Param _book[string]
            * @Param _other[string]
            */
            sign(_newspaper, _website, _wechat, _app, _magzine, _book, _other) { return post(url + '/sign', { newspaper: _newspaper, website: _website, wechat: _wechat, app: _app, magzine: _magzine, book: _book, other: _other }); },
            sync() { return get(url + '/sync'); },
            /**
            * PC网页端使用的登录方法，icode一次失效，注意返回码20 30的情况需要处理，此方法不会返回21
            * @Param _icode[string]
            * @Param _loginUsername[string]
            * @Param _loginPasswordSHA1[string]
            * @Param _weekPassword* : [boolean] #true为当前密码弱密码 
            */
            login(_icode, _loginUsername, _loginPasswordSHA1, _weekPassword) { return post(url + '/login', { icode: _icode, loginUsername: _loginUsername, loginPasswordSHA1: _loginPasswordSHA1, weekPassword: _weekPassword }); },
            idBack_URL() { return url + '/idBack'; },
            /**
            * 上传身份证反正面，post form上传，图像文件的name=file
            */
            uploadIdBack_URL() { return url + '/idBack'; },
            /**
            * 注意，PC端退出登录用
            */
            logout() { return post(url + '/logout'); },
            /**
            * @Param _defaultAuthorName[string]
            * @Param _company[string]
            * @Param _head[string]
            * @Param _address[string]
            * @Param _postCode[string]
            * @Param _comment[string]
            * @Param _email[string]
            * @Param _qq*[string]
            * @Param _wx*[string]
            * @Param _bank*[string]
            * @Param _sheji*[string]
            * @Param _xinwen*[string]
            * @Param _bankAcc*[string]
            * @Param _sheying*[string]
            * @Param _telephone*[string]
            * @Param _topReward*[string]
            */
            update(_defaultAuthorName, _company, _head, _address, _postCode, _comment, _email, _qq, _wx, _bank, _sheji, _xinwen, _bankAcc, _sheying, _telephone, _topReward) { return post(url + '/update', { defaultAuthorName: _defaultAuthorName, company: _company, head: _head, address: _address, postCode: _postCode, comment: _comment, email: _email, qq: _qq, wx: _wx, bank: _bank, sheji: _sheji, xinwen: _xinwen, bankAcc: _bankAcc, sheying: _sheying, telephone: _telephone, topReward: _topReward }); },
            idFront_URL() { return url + '/idFront'; },
            /**
            * 上传身份证正面，post form上传，图像文件的name=file
            */
            uploadIdFront_URL() { return url + '/idFront'; },
            /**
            * 注意，WX小程序退出登录用，此过程会清理openId
            */
            logoutWX() { return post(url + '/logoutWX'); },
            headPic_URL() { return url + '/headImage'; },
            /**
            * 上传头像，post form上传，头像文件的name=file
            */
            uploadHeadPic_URL() { return url + '/headImage'; },
            /**
            * 注意，此接口是URL形式，适用于浏览器直接使用，小程序默认资源访问没有session，所以尽量不要用拼接openId方式使用此方法
            */
            icodeImage_URL() { return url + '/icodeImage'; },
            /**
            * @Param _code*[string]
            */
            bindMobile(_code) { return post(url + '/bindMobile', { code: _code }); },
            /**
            * 上传注册时使用的身份证接口，和unregistImag使用方式相同，但是返回内容不是uuid，而是{uuid，idNo}，post form上传，图像文件的name=file
            */
            unregistID_URL() { return url + '/unregistID'; },
            /**
            * 自充值启动接口，传入重置数额，注意传入的数额是double型，单位是元
            * @Param _value[float]
            */
            selfCharge(_value) { return post(url + '/selfCharge', { value: _value }); },
            /**
            * WX根据code找回openId并登录，只用于小程序，此过程不验证密码，不会进入密码二次验证过程
            * @Param _code[string]
            */
            loginByCode(_code) { return post(url + '/loginByCode', { code: _code }); },
            /**
            * @Param _chnName[string]
            */
            checkChnName(_chnName) { return post(url + '/checkChnName', { chnName: _chnName }); },
            headPicOri_URL() { return url + '/headImageOri'; },
            /**
            * 如果在弱密码必须重置的情况下重置密码，会返回code 31，并自动登出，需要重新登录，正常重设密码不会引起强制重新登录，注意，只有在登录，或者RESET_PASSWORD_REQUIRED情况下才可以调用
            * @Param _oldPasswordSHA1[string]
            * @Param _newPasswordSHA1[string]
            */
            resetPassword(_oldPasswordSHA1, _newPasswordSHA1) { return post(url + '/resetPassword', { oldPasswordSHA1: _oldPasswordSHA1, newPasswordSHA1: _newPasswordSHA1 }); },
            /**
            * 上传注册时使用的图像，post form上传，图像文件的name=file
            */
            uploadPicUnregisted_URL() { return url + '/unregistImage'; },
            /**
            * 对已经上传的头像进行剪切
            * @Param _x1[float]
            * @Param _y1[float]
            * @Param _x2[float]
            * @Param _y2[float]
            */
            headImageCut(_x1, _y1, _x2, _y2) { return post(url + '/headImage' + '/cut', { x1: _x1, y1: _y1, x2: _x2, y2: _y2 }); },
            /**
            * @Param _loginName[string]
            */
            checkLoginName(_loginName) { return post(url + '/checkLoginName', { loginName: _loginName }); },
            /**
            * 注意，此版本开始，此接口需要icode。图形验证码只能使用一次，如果错误，必须刷新图形验证码，此登录不会触发二次登录验证，但是会触发弱密码强制修改
            * @Param _icode[string]
            * @Param _loginUsername[string]
            * @Param _loginPasswordSHA1[string]
            * @Param _openId[string]
            * @Param _weekPassword*[boolean]
            */
            loginWithOpenId(_icode, _loginUsername, _loginPasswordSHA1, _openId, _weekPassword) { return post(url + '/loginWithOpenId', { icode: _icode, loginUsername: _loginUsername, loginPasswordSHA1: _loginPasswordSHA1, openId: _openId, weekPassword: _weekPassword }); },
            /**
            * 手动触发充值更新，注意此方法的更新是同步的而不是异步的，调用之后马上调用sync，充值数值生效
            */
            checkSelfCharge() { return post(url + '/checkSelfCharge'); },
            /**
            * 当前登录账户的余额的变化记录，分页，时间逆序排列
            * @Param _page*[int]
            * @Param _pageSize*[int]
            */
            creditChangeLog(_page, _pageSize) { return post(url + '/creditChangeLog', { page: _page, pageSize: _pageSize }); },
            /**
            * 注意，此接口是get模式，适用于小程序直接取回icode图片的base64模式
            */
            icodeImageBase64() { return get(url + '/icodeImageBase64'); },
            /**
            * @Param _loginName : [string] #登录名 
            * @Param _loginPasswordSHA1 : [string] #登录密码的SHA1 
            * @Param _chnName : [string] #中文名 
            * @Param _extra : #用于客户端注册时附加信息的对象，注意，此对象中所有值结构上都是可选值，但前台可以根据使用单位规则，设置必须要传的字段 其它信息 {
                     address:[string] #地址 
                     bank:[string] #银行名称 
                     bankAcc:[string] #银行账户 
                     comment:[string] #个人简介 
                     company:[string] #单位 
                     email:[string] #邮箱 
                     exclusive:[boolean] #是否独家代理，默认true 
                     head:[string] #职务 
                     headImage:[string] #头像上传token 
                     idBack:[string] #身份证背面token 
                     idFront:[string] #身份证正面token 
                     idNo:[string] #身份证号码 
                     postCode:[string] #邮编 
                     qq:[string] #QQ 
                     sheji:[string] #何级设计师协会会员(选填) 
                     sheying:[string] #何级摄影家协会会员(选填) 
                     telephone:[string] #座机 
                     topProducesList: #代表作列表(选填)[{uuid,text}] [list:{
                             text:[string]
                             uuid:[string]
                         }                         ]
                     topReward:[string] #获得过最高级别的专业奖(选填) 
                     wx:[string] #微信 
                     xinwen:[string] #何级新闻摄影学协会会员(选填) 
                 }
            * @Param _roleId* : [int] #选择注册成某个角色的ID，注意这个ID传错会导致问题，不传或0则用默认角色 
            */
            registerUploader(_loginName, _loginPasswordSHA1, _chnName, _extra, _roleId) { return post(url + '/registerUploader', { loginName: _loginName, loginPasswordSHA1: _loginPasswordSHA1, chnName: _chnName, extra: JSON.stringify(_extra), roleId: _roleId }); },
            approveStatusList() { return get(url + '/approveStatusList'); },
            /**
            * 重发二次验证短信
            */
            resendDualAuthSMS() { return post(url + '/resendDualAuthSMS'); },
            /**
            * @Param _mobile*[string]
            */
            confirmSMS(_mobile) { return post(url + '/requestConfirmSMS', { mobile: _mobile }); },
            /**
            * 未注册情况下，通过uuid对已经上传的图片进行剪裁，此操作不止可以用于头像，也可以用于身份证，注意，此状态下会直接覆盖已上传的头像，未剪裁版本不会被保存
            * @Param _imageUUID : [string] #图像上传token 
            * @Param _x1[float]
            * @Param _y1[float]
            * @Param _x2[float]
            * @Param _y2[float]
            */
            uploadPicUnregistedCut(_imageUUID, _x1, _y1, _x2, _y2) { return post(url + '/unregistImage' + '/cut', { imageUUID: _imageUUID, x1: _x1, y1: _y1, x2: _x2, y2: _y2 }); },
            /**
            * @Param _loginName : [string] #登录名 
            * @Param _loginPasswordSHA1 : [string] #登录密码的SHA1 
            * @Param _chnName : [string] #中文名 
            * @Param _extra : #用于客户端注册时附加信息的对象，注意，此对象中所有值结构上都是可选值，但前台可以根据使用单位规则，设置必须要传的字段 其它信息 {
                     address:[string] #地址 
                     bank:[string] #银行名称 
                     bankAcc:[string] #银行账户 
                     comment:[string] #个人简介 
                     company:[string] #单位 
                     email:[string] #邮箱 
                     exclusive:[boolean] #是否独家代理，默认true 
                     head:[string] #职务 
                     headImage:[string] #头像上传token 
                     idBack:[string] #身份证背面token 
                     idFront:[string] #身份证正面token 
                     idNo:[string] #身份证号码 
                     postCode:[string] #邮编 
                     qq:[string] #QQ 
                     sheji:[string] #何级设计师协会会员(选填) 
                     sheying:[string] #何级摄影家协会会员(选填) 
                     telephone:[string] #座机 
                     topProducesList: #代表作列表(选填)[{uuid,text}] [list:{
                             text:[string]
                             uuid:[string]
                         }                         ]
                     topReward:[string] #获得过最高级别的专业奖(选填) 
                     wx:[string] #微信 
                     xinwen:[string] #何级新闻摄影学协会会员(选填) 
                 }
            * @Param _roleId* : [int] #选择注册成某个角色的ID，注意这个ID传错会导致问题，不传或0则用默认角色 
            */
            registerDownloader(_loginName, _loginPasswordSHA1, _chnName, _extra, _roleId) { return post(url + '/registerDownloader', { loginName: _loginName, loginPasswordSHA1: _loginPasswordSHA1, chnName: _chnName, extra: JSON.stringify(_extra), roleId: _roleId }); },
            /**
            * 二次验证手机短信的验证方法，成功就登录成功，失败2就是验证码错误或者当前不应该调用验证方法，直接输出msg即可，验证码最多重试5次就失效，失效那次会返回code 21，这时候要提示，如果要重新发送，则调resendDualAuthSMS
            * @Param _mobileCode : [string] #手机验证码 
            */
            confirmCodeForLogin(_mobileCode) { return post(url + '/confirmCodeForLogin', { mobileCode: _mobileCode }); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            */
            confirmCodeForRegist(_mobileCode) { return post(url + '/confirmCodeForRegist', { mobileCode: _mobileCode }); },
            /**
            * @Param _mobile*[string]
            */
            requestSMSForRegister(_mobile) { return post(url + '/requestSMSForRegister', { mobile: _mobile }); },
            /**
            * @Param _newPasswordSHA1[string]
            */
            resetPasswordAfterConfirm(_newPasswordSHA1) { return post(url + '/resetPasswordAfterConfirm', { newPasswordSHA1: _newPasswordSHA1 }); },
            /**
            * @Param _mobile[string]
            * @Param _icode[string]
            */
            requestSMSForResetPassword(_mobile, _icode) { return post(url + '/requestSMSForResetPassword', { mobile: _mobile, icode: _icode }); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            */
            findBackPassword(_mobileCode) { return post(url + '/confirmCodeForfindBackPassword', { mobileCode: _mobileCode }); },
            /**
            * @Param _imageUUID : [string] #图像上传token 
            */
            unregistedImageGet_URL(_imageUUID) { return url + '/unregistedImageGet' + '/' + _imageUUID; },
        }
    },
    /* 旧版本用户 */
    user_old() {
        var url = prefixURL + '/user_old'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('user_old resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * @Param _newspaper[string]
            * @Param _website[string]
            * @Param _wechat[string]
            * @Param _app[string]
            * @Param _magzine[string]
            * @Param _book[string]
            * @Param _other[string]
            */
            sign(_newspaper, _website, _wechat, _app, _magzine, _book, _other) { return post(url + '/sign', { newspaper: _newspaper, website: _website, wechat: _wechat, app: _app, magzine: _magzine, book: _book, other: _other }); },
            sync() { return get(url + '/sync'); },
            /**
            * @Param _icode[string]
            * @Param _loginUsername[string]
            * @Param _loginPasswordSHA1[string]
            */
            login(_icode, _loginUsername, _loginPasswordSHA1) { return post(url + '/login', { icode: _icode, loginUsername: _loginUsername, loginPasswordSHA1: _loginPasswordSHA1 }); },
            idBack_URL() { return url + '/idBack'; },
            /**
            * 上传身份证反正面，post form上传，图像文件的name=file
            */
            uploadIdBack_URL() { return url + '/idBack'; },
            logout() { return post(url + '/logout'); },
            /**
            * @Param _defaultAuthorName[string]
            * @Param _company[string]
            * @Param _head[string]
            * @Param _address[string]
            * @Param _postCode[string]
            * @Param _comment[string]
            * @Param _email[string]
            * @Param _qq*[string]
            * @Param _wx*[string]
            * @Param _bank*[string]
            * @Param _idNo*[string]
            * @Param _sheji*[string]
            * @Param _xinwen*[string]
            * @Param _bankAcc*[string]
            * @Param _sheying*[string]
            * @Param _telephone*[string]
            * @Param _topReward*[string]
            */
            update(_defaultAuthorName, _company, _head, _address, _postCode, _comment, _email, _qq, _wx, _bank, _idNo, _sheji, _xinwen, _bankAcc, _sheying, _telephone, _topReward) { return post(url + '/update', { defaultAuthorName: _defaultAuthorName, company: _company, head: _head, address: _address, postCode: _postCode, comment: _comment, email: _email, qq: _qq, wx: _wx, bank: _bank, idNo: _idNo, sheji: _sheji, xinwen: _xinwen, bankAcc: _bankAcc, sheying: _sheying, telephone: _telephone, topReward: _topReward }); },
            idFront_URL() { return url + '/idFront'; },
            /**
            * 上传身份证正面，post form上传，图像文件的name=file
            */
            uploadIdFront_URL() { return url + '/idFront'; },
            headPic_URL() { return url + '/headImage'; },
            /**
            * 上传头像，post form上传，头像文件的name=file
            */
            uploadHeadPic_URL() { return url + '/headImage'; },
            icodeImage_URL() { return url + '/icodeImage'; },
            /**
            * @Param _code*[string]
            */
            bindMobile(_code) { return post(url + '/bindMobile', { code: _code }); },
            /**
            * 上传注册时使用的身份证接口，和unregistImag使用方式相同，但是返回内容不是uuid，而是{uuid，idNo}，post form上传，图像文件的name=file
            */
            unregistID_URL() { return url + '/unregistID'; },
            /**
            * 自充值启动接口，传入重置数额，注意传入的数额是double型，单位是元
            * @Param _value[float]
            */
            selfCharge(_value) { return post(url + '/selfCharge', { value: _value }); },
            /**
            * @Param _code[string]
            */
            loginByCode(_code) { return post(url + '/loginByCode', { code: _code }); },
            /**
            * @Param _chnName[string]
            */
            checkChnName(_chnName) { return post(url + '/checkChnName', { chnName: _chnName }); },
            headPicOri_URL() { return url + '/headImageOri'; },
            /**
            * @Param _oldPasswordSHA1[string]
            * @Param _newPasswordSHA1[string]
            */
            resetPassword(_oldPasswordSHA1, _newPasswordSHA1) { return post(url + '/resetPassword', { oldPasswordSHA1: _oldPasswordSHA1, newPasswordSHA1: _newPasswordSHA1 }); },
            /**
            * 上传注册时使用的图像，post form上传，图像文件的name=file
            */
            uploadPicUnregisted_URL() { return url + '/unregistImage'; },
            /**
            * 对已经上传的头像进行剪切
            * @Param _x1[float]
            * @Param _y1[float]
            * @Param _x2[float]
            * @Param _y2[float]
            */
            headImageCut(_x1, _y1, _x2, _y2) { return post(url + '/headImage' + '/cut', { x1: _x1, y1: _y1, x2: _x2, y2: _y2 }); },
            /**
            * @Param _loginName[string]
            */
            checkLoginName(_loginName) { return post(url + '/checkLoginName', { loginName: _loginName }); },
            /**
            * @Param _loginUsername[string]
            * @Param _loginPasswordSHA1[string]
            * @Param _openId[string]
            */
            loginWithOpenId(_loginUsername, _loginPasswordSHA1, _openId) { return post(url + '/loginWithOpenId', { loginUsername: _loginUsername, loginPasswordSHA1: _loginPasswordSHA1, openId: _openId }); },
            /**
            * 手动触发充值更新，注意此方法的更新是同步的而不是异步的，调用之后马上调用sync，充值数值生效
            */
            checkSelfCharge() { return post(url + '/checkSelfCharge'); },
            /**
            * 当前登录账户的余额的变化记录，分页，时间逆序排列
            * @Param _page*[int]
            * @Param _pageSize*[int]
            */
            creditChangeLog(_page, _pageSize) { return post(url + '/creditChangeLog', { page: _page, pageSize: _pageSize }); },
            icodeImageBase64() { return get(url + '/icodeImageBase64'); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            * @Param _exclusive : [boolean] #是否是独家代理用户 
            * @Param _loginName : [string] #登录名 
            * @Param _loginPasswordSHA1 : [string] #登录密码的SHA1 
            * @Param _chnName : [string] #中文名 
            * @Param _address : [string] #地址 
            * @Param _postCode : [string] #邮编 
            * @Param _company : [string] #单位 
            * @Param _head : [string] #职务 
            * @Param _comment : [string] #个人简介 
            * @Param _qq* : [string] #QQ 
            * @Param _wx* : [string] #微信 
            * @Param _bank* : [string] #银行名称 
            * @Param _idNo* : [string] #身份证号码 
            * @Param _email* : [string] #邮箱 
            * @Param _sheji* : [string] #何级设计师协会会员(选填) 
            * @Param _idBack* : [string] #身份证背面token 
            * @Param _xinwen* : [string] #何级新闻摄影学协会会员(选填) 
            * @Param _bankAcc* : [string] #银行账户 
            * @Param _idFront* : [string] #身份证正面token 
            * @Param _sheying* : [string] #何级摄影家协会会员(选填) 
            * @Param _topPics* : [string] #代表作列表(选填)[{uuid,text}] 
            * @Param _telephone* : [string] #座机 
            * @Param _headImage* : [string] #头像上传token 
            * @Param _topReward* : [string] #获得过最高级别的专业奖(选填) 
            */
            registerUploader(_mobileCode, _exclusive, _loginName, _loginPasswordSHA1, _chnName, _address, _postCode, _company, _head, _comment, _qq, _wx, _bank, _idNo, _email, _sheji, _idBack, _xinwen, _bankAcc, _idFront, _sheying, _topPics, _telephone, _headImage, _topReward) { return post(url + '/registerUploader', { mobileCode: _mobileCode, exclusive: _exclusive, loginName: _loginName, loginPasswordSHA1: _loginPasswordSHA1, chnName: _chnName, address: _address, postCode: _postCode, company: _company, head: _head, comment: _comment, qq: _qq, wx: _wx, bank: _bank, idNo: _idNo, email: _email, sheji: _sheji, idBack: _idBack, xinwen: _xinwen, bankAcc: _bankAcc, idFront: _idFront, sheying: _sheying, topPics: JSON.stringify(_topPics), telephone: _telephone, headImage: _headImage, topReward: _topReward }); },
            approveStatusList() { return get(url + '/approveStatusList'); },
            /**
            * @Param _mobile*[string]
            */
            confirmSMS(_mobile) { return post(url + '/requestConfirmSMS', { mobile: _mobile }); },
            /**
            * 未注册情况下，通过uuid对已经上传的图片进行剪裁，此操作不止可以用于头像，也可以用于身份证，注意，此状态下会直接覆盖已上传的头像，未剪裁版本不会被保存
            * @Param _imageUUID : [string] #图像上传token 
            * @Param _x1[float]
            * @Param _y1[float]
            * @Param _x2[float]
            * @Param _y2[float]
            */
            uploadPicUnregistedCut(_imageUUID, _x1, _y1, _x2, _y2) { return post(url + '/unregistImage' + '/cut', { imageUUID: _imageUUID, x1: _x1, y1: _y1, x2: _x2, y2: _y2 }); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            * @Param _loginName : [string] #登录名 
            * @Param _loginPasswordSHA1 : [string] #登录密码的SHA1 
            * @Param _chnName : [string] #中文名 
            * @Param _address : [string] #地址 
            * @Param _postCode : [string] #邮编 
            * @Param _company : [string] #单位 
            * @Param _head : [string] #职务 
            * @Param _qq* : [string] #QQ 
            * @Param _wx* : [string] #微信 
            * @Param _bank* : [string] #银行名称 
            * @Param _idNo* : [string] #身份证号码 
            * @Param _email* : [string] #邮箱 
            * @Param _idBack* : [string] #身份证背面token 
            * @Param _idFront* : [string] #身份证正面token 
            * @Param _bankAcc* : [string] #银行账户 
            * @Param _telephone* : [string] #座机 
            */
            registerDownloader(_mobileCode, _loginName, _loginPasswordSHA1, _chnName, _address, _postCode, _company, _head, _qq, _wx, _bank, _idNo, _email, _idBack, _idFront, _bankAcc, _telephone) { return post(url + '/registerDownloader', { mobileCode: _mobileCode, loginName: _loginName, loginPasswordSHA1: _loginPasswordSHA1, chnName: _chnName, address: _address, postCode: _postCode, company: _company, head: _head, qq: _qq, wx: _wx, bank: _bank, idNo: _idNo, email: _email, idBack: _idBack, idFront: _idFront, bankAcc: _bankAcc, telephone: _telephone }); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            */
            confirmCodeForRegist(_mobileCode) { return post(url + '/confirmCodeForRegist', { mobileCode: _mobileCode }); },
            /**
            * @Param _mobile*[string]
            */
            requestSMSForRegister(_mobile) { return post(url + '/requestSMSForRegister', { mobile: _mobile }); },
            /**
            * @Param _newPasswordSHA1[string]
            */
            resetPasswordAfterConfirm(_newPasswordSHA1) { return post(url + '/resetPasswordAfterConfirm', { newPasswordSHA1: _newPasswordSHA1 }); },
            /**
            * @Param _mobile[string]
            * @Param _icode[string]
            */
            requestSMSForResetPassword(_mobile, _icode) { return post(url + '/requestSMSForResetPassword', { mobile: _mobile, icode: _icode }); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            */
            findBackPassword(_mobileCode) { return post(url + '/confirmCodeForfindBackPassword', { mobileCode: _mobileCode }); },
            /**
            * @Param _imageUUID : [string] #图像上传token 
            */
            unregistedImageGet_URL(_imageUUID) { return url + '/unregistedImageGet' + '/' + _imageUUID; },
        }
    },
    /* 用户，新版 */
    user() {
        var url = prefixURL + '/user'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('user resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * @Param _newspaper[string]
            * @Param _website[string]
            * @Param _wechat[string]
            * @Param _app[string]
            * @Param _magzine[string]
            * @Param _book[string]
            * @Param _other[string]
            */
            sign(_newspaper, _website, _wechat, _app, _magzine, _book, _other) { return post(url + '/sign', { newspaper: _newspaper, website: _website, wechat: _wechat, app: _app, magzine: _magzine, book: _book, other: _other }); },
            sync() { return get(url + '/sync'); },
            /**
            * PC网页端使用的登录方法，icode一次失效，注意返回码20 30的情况需要处理，此方法不会返回21
            * @Param _icode[string]
            * @Param _loginUsername[string]
            * @Param _loginPasswordSHA1[string]
            * @Param _weekPassword* : [boolean] #true为当前密码弱密码 
            */
            login(_icode, _loginUsername, _loginPasswordSHA1, _weekPassword) { return post(url + '/login', { icode: _icode, loginUsername: _loginUsername, loginPasswordSHA1: _loginPasswordSHA1, weekPassword: _weekPassword }); },
            idBack_URL() { return url + '/idBack'; },
            /**
            * 上传身份证反正面，post form上传，图像文件的name=file
            */
            uploadIdBack_URL() { return url + '/idBack'; },
            /**
            * 注意，PC端退出登录用
            */
            logout() { return post(url + '/logout'); },
            /**
            * @Param _defaultAuthorName[string]
            * @Param _company[string]
            * @Param _head[string]
            * @Param _address[string]
            * @Param _postCode[string]
            * @Param _comment[string]
            * @Param _email[string]
            * @Param _qq*[string]
            * @Param _wx*[string]
            * @Param _bank*[string]
            * @Param _sheji*[string]
            * @Param _xinwen*[string]
            * @Param _bankAcc*[string]
            * @Param _sheying*[string]
            * @Param _telephone*[string]
            * @Param _topReward*[string]
            */
            update(_defaultAuthorName, _company, _head, _address, _postCode, _comment, _email, _qq, _wx, _bank, _sheji, _xinwen, _bankAcc, _sheying, _telephone, _topReward) { return post(url + '/update', { defaultAuthorName: _defaultAuthorName, company: _company, head: _head, address: _address, postCode: _postCode, comment: _comment, email: _email, qq: _qq, wx: _wx, bank: _bank, sheji: _sheji, xinwen: _xinwen, bankAcc: _bankAcc, sheying: _sheying, telephone: _telephone, topReward: _topReward }); },
            idFront_URL() { return url + '/idFront'; },
            /**
            * 上传身份证正面，post form上传，图像文件的name=file
            */
            uploadIdFront_URL() { return url + '/idFront'; },
            /**
            * 注意，WX小程序退出登录用，此过程会清理openId
            */
            logoutWX() { return post(url + '/logoutWX'); },
            headPic_URL() { return url + '/headImage'; },
            /**
            * 上传头像，post form上传，头像文件的name=file
            */
            uploadHeadPic_URL() { return url + '/headImage'; },
            /**
            * 注意，此接口是URL形式，适用于浏览器直接使用，小程序默认资源访问没有session，所以尽量不要用拼接openId方式使用此方法
            */
            icodeImage_URL() { return url + '/icodeImage'; },
            /**
            * @Param _code*[string]
            */
            bindMobile(_code) { return post(url + '/bindMobile', { code: _code }); },
            /**
            * 上传注册时使用的身份证接口，和unregistImag使用方式相同，但是返回内容不是uuid，而是{uuid，idNo}，post form上传，图像文件的name=file
            */
            unregistID_URL() { return url + '/unregistID'; },
            /**
            * 自充值启动接口，传入重置数额，注意传入的数额是double型，单位是元
            * @Param _value[float]
            */
            selfCharge(_value) { return post(url + '/selfCharge', { value: _value }); },
            /**
            * WX根据code找回openId并登录，只用于小程序，此过程不验证密码，不会进入密码二次验证过程
            * @Param _code[string]
            */
            loginByCode(_code) { return post(url + '/loginByCode', { code: _code }); },
            /**
            * @Param _chnName[string]
            */
            checkChnName(_chnName) { return post(url + '/checkChnName', { chnName: _chnName }); },
            headPicOri_URL() { return url + '/headImageOri'; },
            /**
            * 此方法必须用手机号码登录，升级版PC网页端使用的登录方法，传入的icode用于拼接Password，方法是sha1(icode + passwordsha1)，icode一次失效，注意返回码20 30的情况需要处理，此方法不会返回21
            * @Param _icode[string]
            * @Param _loginMobile[string]
            * @Param _passwordSHA1[string]
            * @Param _weekPassword* : [boolean] #true为当前密码弱密码 
            */
            loginAdvanced(_icode, _loginMobile, _passwordSHA1, _weekPassword) { return post(url + '/loginAdvanced', { icode: _icode, loginMobile: _loginMobile, passwordSHA1: _passwordSHA1, weekPassword: _weekPassword }); },
            /**
            * 如果在弱密码必须重置的情况下重置密码，会返回code 31，并自动登出，需要重新登录，正常重设密码不会引起强制重新登录，注意，只有在登录，或者RESET_PASSWORD_REQUIRED情况下才可以调用
            * @Param _oldPasswordSHA1[string]
            * @Param _newPasswordSHA1[string]
            */
            resetPassword(_oldPasswordSHA1, _newPasswordSHA1) { return post(url + '/resetPassword', { oldPasswordSHA1: _oldPasswordSHA1, newPasswordSHA1: _newPasswordSHA1 }); },
            /**
            * 上传注册时使用的图像，post form上传，图像文件的name=file
            */
            uploadPicUnregisted_URL() { return url + '/unregistImage'; },
            /**
            * 对已经上传的头像进行剪切
            * @Param _x1[float]
            * @Param _y1[float]
            * @Param _x2[float]
            * @Param _y2[float]
            */
            headImageCut(_x1, _y1, _x2, _y2) { return post(url + '/headImage' + '/cut', { x1: _x1, y1: _y1, x2: _x2, y2: _y2 }); },
            /**
            * @Param _loginName[string]
            */
            checkLoginName(_loginName) { return post(url + '/checkLoginName', { loginName: _loginName }); },
            /**
            * 注意，此版本开始，此接口需要icode。图形验证码只能使用一次，如果错误，必须刷新图形验证码，此登录不会触发二次登录验证，但是会触发弱密码强制修改
            * @Param _icode[string]
            * @Param _loginUsername[string]
            * @Param _loginPasswordSHA1[string]
            * @Param _openId[string]
            * @Param _weekPassword*[boolean]
            */
            loginWithOpenId(_icode, _loginUsername, _loginPasswordSHA1, _openId, _weekPassword) { return post(url + '/loginWithOpenId', { icode: _icode, loginUsername: _loginUsername, loginPasswordSHA1: _loginPasswordSHA1, openId: _openId, weekPassword: _weekPassword }); },
            /**
            * 手动触发充值更新，注意此方法的更新是同步的而不是异步的，调用之后马上调用sync，充值数值生效
            */
            checkSelfCharge() { return post(url + '/checkSelfCharge'); },
            /**
            * 当前登录账户的余额的变化记录，分页，时间逆序排列
            * @Param _page*[int]
            * @Param _pageSize*[int]
            */
            creditChangeLog(_page, _pageSize) { return post(url + '/creditChangeLog', { page: _page, pageSize: _pageSize }); },
            /**
            * 注意，此接口是get模式，适用于小程序直接取回icode图片的base64模式
            */
            icodeImageBase64() { return get(url + '/icodeImageBase64'); },
            /**
            * @Param _loginName : [string] #登录名 
            * @Param _loginPasswordSHA1 : [string] #登录密码的SHA1 
            * @Param _chnName : [string] #中文名 
            * @Param _extra : #用于客户端注册时附加信息的对象，注意，此对象中所有值结构上都是可选值，但前台可以根据使用单位规则，设置必须要传的字段 其它信息 {
                     address:[string] #地址 
                     bank:[string] #银行名称 
                     bankAcc:[string] #银行账户 
                     comment:[string] #个人简介 
                     company:[string] #单位 
                     email:[string] #邮箱 
                     exclusive:[boolean] #是否独家代理，默认true 
                     head:[string] #职务 
                     headImage:[string] #头像上传token 
                     idBack:[string] #身份证背面token 
                     idFront:[string] #身份证正面token 
                     idNo:[string] #身份证号码 
                     postCode:[string] #邮编 
                     qq:[string] #QQ 
                     sheji:[string] #何级设计师协会会员(选填) 
                     sheying:[string] #何级摄影家协会会员(选填) 
                     telephone:[string] #座机 
                     topProducesList: #代表作列表(选填)[{uuid,text}] [list:{
                             text:[string]
                             uuid:[string]
                         }                         ]
                     topReward:[string] #获得过最高级别的专业奖(选填) 
                     wx:[string] #微信 
                     xinwen:[string] #何级新闻摄影学协会会员(选填) 
                 }
            * @Param _roleId* : [int] #选择注册成某个角色的ID，注意这个ID传错会导致问题，不传或0则用默认角色 
            */
            registerUploader(_loginName, _loginPasswordSHA1, _chnName, _extra, _roleId) { return post(url + '/registerUploader', { loginName: _loginName, loginPasswordSHA1: _loginPasswordSHA1, chnName: _chnName, extra: JSON.stringify(_extra), roleId: _roleId }); },
            approveStatusList() { return get(url + '/approveStatusList'); },
            /**
            * PC网页端使用的登录方法，用短信验证码登录，前置方法为requestSMSForLogin
            * @Param _code[string]
            */
            loginByMobileCode(_code) { return post(url + '/loginByMobileCode', { code: _code }); },
            /**
            * 重发二次验证短信
            */
            resendDualAuthSMS() { return post(url + '/resendDualAuthSMS'); },
            /**
            * 申请绑定号码短信，此方法需要验证icode
            * @Param _icode[string]
            * @Param _mobile*[string]
            */
            confirmSMS(_icode, _mobile) { return post(url + '/requestConfirmSMS', { icode: _icode, mobile: _mobile }); },
            /**
            * 未注册情况下，通过uuid对已经上传的图片进行剪裁，此操作不止可以用于头像，也可以用于身份证，注意，此状态下会直接覆盖已上传的头像，未剪裁版本不会被保存
            * @Param _imageUUID : [string] #图像上传token 
            * @Param _x1[float]
            * @Param _y1[float]
            * @Param _x2[float]
            * @Param _y2[float]
            */
            uploadPicUnregistedCut(_imageUUID, _x1, _y1, _x2, _y2) { return post(url + '/unregistImage' + '/cut', { imageUUID: _imageUUID, x1: _x1, y1: _y1, x2: _x2, y2: _y2 }); },
            /**
            * 验证码登录短信获取
            * @Param _icode[string]
            * @Param _mobile*[string]
            */
            requestSMSForLogin(_icode, _mobile) { return post(url + '/requestSMSForLogin', { icode: _icode, mobile: _mobile }); },
            /**
            * @Param _loginName : [string] #登录名 
            * @Param _loginPasswordSHA1 : [string] #登录密码的SHA1 
            * @Param _chnName : [string] #中文名 
            * @Param _extra : #用于客户端注册时附加信息的对象，注意，此对象中所有值结构上都是可选值，但前台可以根据使用单位规则，设置必须要传的字段 其它信息 {
                     address:[string] #地址 
                     bank:[string] #银行名称 
                     bankAcc:[string] #银行账户 
                     comment:[string] #个人简介 
                     company:[string] #单位 
                     email:[string] #邮箱 
                     exclusive:[boolean] #是否独家代理，默认true 
                     head:[string] #职务 
                     headImage:[string] #头像上传token 
                     idBack:[string] #身份证背面token 
                     idFront:[string] #身份证正面token 
                     idNo:[string] #身份证号码 
                     postCode:[string] #邮编 
                     qq:[string] #QQ 
                     sheji:[string] #何级设计师协会会员(选填) 
                     sheying:[string] #何级摄影家协会会员(选填) 
                     telephone:[string] #座机 
                     topProducesList: #代表作列表(选填)[{uuid,text}] [list:{
                             text:[string]
                             uuid:[string]
                         }                         ]
                     topReward:[string] #获得过最高级别的专业奖(选填) 
                     wx:[string] #微信 
                     xinwen:[string] #何级新闻摄影学协会会员(选填) 
                 }
            * @Param _roleId* : [int] #选择注册成某个角色的ID，注意这个ID传错会导致问题，不传或0则用默认角色 
            */
            registerDownloader(_loginName, _loginPasswordSHA1, _chnName, _extra, _roleId) { return post(url + '/registerDownloader', { loginName: _loginName, loginPasswordSHA1: _loginPasswordSHA1, chnName: _chnName, extra: JSON.stringify(_extra), roleId: _roleId }); },
            /**
            * 二次验证手机短信的验证方法，成功就登录成功，失败2就是验证码错误或者当前不应该调用验证方法，直接输出msg即可，验证码有失效次数限制，失效那次会返回code 21，这时候要提示，如果要重新发送，则调resendDualAuthSMS
            * @Param _mobileCode : [string] #手机验证码 
            */
            confirmCodeForLogin(_mobileCode) { return post(url + '/confirmCodeForLogin', { mobileCode: _mobileCode }); },
            /**
            * @Param _mobileCode : [string] #手机验证码 
            */
            confirmCodeForRegist(_mobileCode) { return post(url + '/confirmCodeForRegist', { mobileCode: _mobileCode }); },
            /**
            * 申请注册号码短信，此方法需要验证icode
            * @Param _icode[string]
            * @Param _mobile*[string]
            */
            requestSMSForRegister(_icode, _mobile) { return post(url + '/requestSMSForRegister', { icode: _icode, mobile: _mobile }); },
            /**
            * @Param _newPasswordSHA1[string]
            */
            resetPasswordAfterConfirm(_newPasswordSHA1) { return post(url + '/resetPasswordAfterConfirm', { newPasswordSHA1: _newPasswordSHA1 }); },
            /**
            * @Param _mobile[string]
            * @Param _icode[string]
            */
            requestSMSForResetPassword(_mobile, _icode) { return post(url + '/requestSMSForResetPassword', { mobile: _mobile, icode: _icode }); },
            /**
            * 如果是验证码超时需要重新获取，则返回-30
            * @Param _mobileCode : [string] #手机验证码 
            */
            findBackPassword(_mobileCode) { return post(url + '/confirmCodeForfindBackPassword', { mobileCode: _mobileCode }); },
            /**
            * @Param _imageUUID : [string] #图像上传token 
            */
            unregistedImageGet_URL(_imageUUID) { return url + '/unregistedImageGet' + '/' + _imageUUID; },
        }
    },
    /* 个人稿件，仅用于上传稿件，查阅个人稿库 */
    myPost() {
        var url = prefixURL + '/myPost'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('myPost resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 获取自己的稿件列表
            * @Param _page : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listPost(_page, _pageSize) { return post(url, { page: _page, pageSize: _pageSize }); },
            /**
            * 获取自己的购物车，注意，此方法中的pageSize没有限制，可以强行加载全部购物车以方便操作
            * @Param _page* : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listPicCart(_page, _pageSize) { return post(url + '/cart', { page: _page, pageSize: _pageSize }); },
            /**
            * 上传PDG，pdf file name = file，返回uuid
            */
            upPDF_URL() { return url + '/upPDF'; },
            /**
            * 上传图片，image file name = file，返回uuid
            */
            upPic_URL() { return url + '/upPic'; },
            /**
            * 上传稿件
            * @Param _post#用于客户端上传稿件，视频稿和图片稿都是此结构 {
                     title:[string]
                     author:[string]
                     authorExtra1:[string]
                     authorExtra2:[string]
                     authorExtra3:[string]
                     authorExtra4:[string]
                     mainText:[string]
                     mainRichText:[string]
                     note:[string]
                     storeId:[int]
                     eventTime:[Date]
                     eventAddrId:[int]
                     typeIds:[list:{int}]
                     activityTopicIds:[list:{int}] #要参加的比赛id 
                     pics:[list:{
                             uuid:[string]
                             text:[string]
                             tags:[list:{string}]
                         }                         ]
                     tags:[list:{string}]
                     posterUUID:[string]
                     videoUUID:[string]
                     fixedTopicIds:[list:{int}]
                     posterCuts:[list:{float}] #poster的剪裁参数，只有上传视频时使用。如果不剪裁，这个字段留空，如果要剪裁，则必须传四个参数，分别是x1,y1,x2,y2，x1 y1是左上角坐标，x2 y2是右下角坐标，所有坐标的数值，都是相对于图片原始边长上的比例，如果出现x2小于x1，y2小于y1等不合理情况，则会自动忽略此参数。 
                 }
            */
            upPost(_post) { return post(url + '/upPost', { post: JSON.stringify(_post) }); },
            /**
            * 上传视频稿件
            * @Param _post#用于客户端上传稿件，视频稿和图片稿都是此结构 {
                     title:[string]
                     author:[string]
                     authorExtra1:[string]
                     authorExtra2:[string]
                     authorExtra3:[string]
                     authorExtra4:[string]
                     mainText:[string]
                     mainRichText:[string]
                     note:[string]
                     storeId:[int]
                     eventTime:[Date]
                     eventAddrId:[int]
                     typeIds:[list:{int}]
                     activityTopicIds:[list:{int}] #要参加的比赛id 
                     pics:[list:{
                             uuid:[string]
                             text:[string]
                             tags:[list:{string}]
                         }                         ]
                     tags:[list:{string}]
                     posterUUID:[string]
                     videoUUID:[string]
                     fixedTopicIds:[list:{int}]
                     posterCuts:[list:{float}] #poster的剪裁参数，只有上传视频时使用。如果不剪裁，这个字段留空，如果要剪裁，则必须传四个参数，分别是x1,y1,x2,y2，x1 y1是左上角坐标，x2 y2是右下角坐标，所有坐标的数值，都是相对于图片原始边长上的比例，如果出现x2小于x1，y2小于y1等不合理情况，则会自动忽略此参数。 
                 }
            */
            upVideoPost(_post) { return post(url + '/upVideo', { post: JSON.stringify(_post) }); },
            /**
            * 获取自己的待支付订单
            * @Param _page* : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listReadyOrder(_page, _pageSize) { return post(url + '/needPay', { page: _page, pageSize: _pageSize }); },
            /**
            * 保存稿件
            * @Param _post#用于客户端修改稿件保存，视频稿和图片稿都是此结构 {
                     id:[int]
                     title:[string]
                     author:[string]
                     mainText:[string]
                     note:[string]
                     eventTime:[Date]
                     eventAddrId:[int]
                     types:[list:{int}]
                     pics: #视频稿此字段会忽略 [list:#id和uuid不可同时为空 {
                             id:[int]
                             tags:[list:{string}]
                             text:[string]
                             uuid:[string]
                         }                         ]
                     tags:[list:{string}]
                 }
            */
            savePost(_post) { return post(url + '/savePost', { post: JSON.stringify(_post) }); },
            /**
            * 上传纯文本稿件
            * @Param _post#用于客户端上传稿件，视频稿和图片稿都是此结构 {
                     title:[string]
                     author:[string]
                     authorExtra1:[string]
                     authorExtra2:[string]
                     authorExtra3:[string]
                     authorExtra4:[string]
                     mainText:[string]
                     mainRichText:[string]
                     note:[string]
                     storeId:[int]
                     eventTime:[Date]
                     eventAddrId:[int]
                     typeIds:[list:{int}]
                     activityTopicIds:[list:{int}] #要参加的比赛id 
                     pics:[list:{
                             uuid:[string]
                             text:[string]
                             tags:[list:{string}]
                         }                         ]
                     tags:[list:{string}]
                     posterUUID:[string]
                     videoUUID:[string]
                     fixedTopicIds:[list:{int}]
                     posterCuts:[list:{float}] #poster的剪裁参数，只有上传视频时使用。如果不剪裁，这个字段留空，如果要剪裁，则必须传四个参数，分别是x1,y1,x2,y2，x1 y1是左上角坐标，x2 y2是右下角坐标，所有坐标的数值，都是相对于图片原始边长上的比例，如果出现x2小于x1，y2小于y1等不合理情况，则会自动忽略此参数。 
                 }
            */
            upTextPost(_post) { return post(url + '/upTextPost', { post: JSON.stringify(_post) }); },
            /**
            * 获取自己的已支付订单
            * @Param _page* : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listPayedOrder(_page, _pageSize) { return post(url + '/payedOrder', { page: _page, pageSize: _pageSize }); },
            /**
            * 用以分析正文和标题的关键字
            * @Param _title[string]
            * @Param _mainText[string]
            * @Param _author[string]
            */
            analyzeTags(_title, _mainText, _author) { return post(url + '/analyzeTags', { title: _title, mainText: _mainText, author: _author }); },
            /**
            * 上传视频文件，image file name = file，返回文件临时id，注意这个文件名带后缀名
            */
            upVideoFile_URL() { return url + '/upVideoFile'; },
            /**
            * 获取自己的待支付订单
            * @Param _page* : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listWaitApproveOrder(_page, _pageSize) { return post(url + '/needApprove', { page: _page, pageSize: _pageSize }); },
            /**
            * 上传海报图片，image file name = file，返回uuid
            */
            upPosterFile_URL() { return url + '/upPosterFile'; },
            /**
            * 准备废弃。上传图片，image file name = file，返回uuid exif
            */
            upPicWithExif_URL() { return url + '/upPicWithExif'; },
            /**
            * 准备废弃。上传图片，image file name = file，返回uuid
            */
            upPicWithTags_URL() { return url + '/upPicWithTags'; },
            /**
            * 获取自己已经购买的图片
            * @Param _page* : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listPurchasedPic(_page, _pageSize) { return post(url + '/purchasedPics', { page: _page, pageSize: _pageSize }); },
            /**
            * 通过UUID直接读取图片
            * @Param _uuid[string]
            */
            mImage_URL(_uuid) { return url + '/tempPic' + '/' + _uuid; },
            /**
            * 获取自己的支付中订单
            * @Param _page* : [int] #页码 
            * @Param _pageSize* : [int] #页长 
            */
            listProcessingOrder(_page, _pageSize) { return post(url + '/processingOrder', { page: _page, pageSize: _pageSize }); },
            /**
            * 通过UUID直接读取图片的小图，用于PDF模式，注意只有PDF分析之后才能从这里访问，没有PDF上传权不能调用
            * @Param _uuid[string]
            */
            sImage_URL(_uuid) { return url + '/tempImage' + '/' + _uuid; },
            /**
            * 获得当前所有可选择的购买模式
            */
            authorizationModes() { return get(url + '/authorizationMode'); },
            /**
            * 检查稿件上传后工作是否完成
            * @Param _postId[int]
            */
            checkPostAfterWork(_postId) { return post(url + '/checkPostAfterWork', { postId: _postId }); },
            /**
            * 检查pdf的分析情况，code = 13表示进行中，code = 14表示出错，msg直接打印输出,如果成功，则直接返回结果
            * @Param _uuid[string]
            */
            checkPDFAnalyzeStatus(_uuid) { return post(url + '/checkPDFAnalyzeStatus', { uuid: _uuid }); },
        }
    },
    /* 稿件 */
    post() {
        var url = prefixURL + '/post'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('post resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 获得单个稿件
                    */
                    one() { return get(url); },
                    /**
                    * 点UP，此接口在不同情况下会有不同的检测手段
                    */
                    tagUp() { return post(url + '/tagUp'); },
                }
            },
            /**
            * 以临时加密UUID获得单个稿件
            * @Param _postUUID[string]
            */
            byUUID(_postUUID) { return get(url + '/byUUID' + '/' + _postUUID); },
        }
    },
    /* 图片 */
    pic() {
        var url = prefixURL + '/pic'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('pic resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    exif() { return get(url + '/exif'); },
                    /**
                    * 原图
                    */
                    oImage_URL() { return url + '/oImage'; },
                    /**
                    * 大图，用于评选
                    */
                    lImage_URL() { return url + '/lImage'; },
                    /**
                    * 大图，用于首页
                    */
                    tImage_URL() { return url + '/tImage'; },
                    mImage_URL() { return url + '/mImage'; },
                    sImage_URL() { return url + '/sImage'; },
                    /**
                    * 下载用中图
                    */
                    mmImage_URL() { return url + '/mmImage'; },
                    /**
                    * 下载用小图
                    */
                    ssImage_URL() { return url + '/ssImage'; },
                    shareLink() { return post(url + '/shareLink'); },
                }
            },
            /**
            * 批量原图，传入picIds = array 
            * @Param _picIds[string]
            */
            oImageBatch_URL(_picIds) { return url + '/oImage'; },
            /**
            * 批量原图，传入picIds = array 
            * @Param _picIds[string]
            */
            mmImageBatch_URL(_picIds) { return url + '/mmImage'; },
            /**
            * 批量原图，传入picIds = array 
            * @Param _picIds[string]
            */
            ssImageBatch_URL(_picIds) { return url + '/ssImage'; },
            /**
            * 把一组图片签发
            * @Param _picIds : [string] #图片ID字符串 
            */
            signOut(_picIds) { return post(url + '/signOut', { picIds: JSON.stringify(_picIds) }); },
        }
    },
    query() {
        var url = prefixURL + '/query'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('query resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 搜索稿件
            * @Param _query : #Object Post Query {
                     keyword:[string] #如果传如整形数字，则会转为搜索稿件ID 
                     appr:[int] #1=模糊 2=一般 3=精确，精确匹配，返回结果会变少很多但是更为精确 
                     postTypeCode:[int] #文字稿件=1  图片稿=2（默认） 视频稿件=3，不可同时搜索 
                     storeIds:[list:{int}]
                     keyInMain:[boolean]
                     keyInPic:[boolean]
                     typeId:[int]
                     addrId:[int]
                     author:[string]
                     eventFrom:[Date]
                     eventTo:[Date]
                     uploadFrom:[Date]
                     uploadTo:[Date]
                     order:[int] #上传逆序=0（默认） 事件时间逆序=1 上网时间逆序=2 入采编时间逆序=3 关键字关联度=4 
                     withNoLimit:[boolean] #是否强制取消上栏目限制 
                 }
            * @Param _page* : [int] #页数（从1开始） 
            * @Param _pageSize* : [int] #每页数量 
            */
            query(_query, _page, _pageSize) { return post(url + '/query', { query: JSON.stringify(_query), page: _page, pageSize: _pageSize }); },
            /**
            * 搜索我的稿件
            * @Param _query : #Object Post Query {
                     keyword:[string]
                     appr:[int]
                     postTypeCode:[int] #不限制类型=0 富文本=1 图片稿=2（默认） 视频稿件=3，不可同时搜索，0的情况下会把所有类型一起搜索出来 
                     keyInMain:[boolean]
                     keyInPic:[boolean]
                     typeId:[int]
                     addrId:[int]
                     eventFrom:[Date]
                     eventTo:[Date]
                     uploadFrom:[Date]
                     uploadTo:[Date]
                     order:[int] #上传逆序=0（默认） 事件时间逆序=1  关键字关联度=4 
                 }
            * @Param _page* : [int] #页数（从1开始） 
            * @Param _pageSize* : [int] #每页数量 
            */
            queryMy(_query, _page, _pageSize) { return post(url + '/queryMy', { query: JSON.stringify(_query), page: _page, pageSize: _pageSize }); },
            /**
            * 搜索图片
            * @Param _query : #PicQuery {
                     keyword:[string] #如果传如整形数字，则会转为搜索图片ID 
                     appr:[int] #1=模糊 2=一般 3=精确 精确匹配，返回结果会变少很多但是更为精确 
                     keyInPost:[boolean]
                     keyByPic:[boolean]
                     shapes:[list:{int}] #1=正方 2=横图 4=竖图 
                     storeIds:[list:{int}]
                     typeId:[int]
                     addrId:[int]
                     author:[string]
                     eventFrom:[Date]
                     eventTo:[Date]
                     uploadFrom:[Date]
                     uploadTo:[Date]
                     heightFrom:[int]
                     withNoLimit:[boolean] #是否强制取消上栏目限制 
                     heightTo:[int]
                     widthFrom:[int]
                     widthTo:[int]
                     sizeFrom:[string] #size字段如是数字则会作为byte处理，可以允许kb mb k m作为单位 
                     sizeTo:[string] #size字段如是数字则会作为byte处理，可以允许kb mb k m作为单位 
                     order:[int] #0=ORDER_BY_UP(DEFAULT) 1=ORDER_BY_EVENT 4=ORDER_BY_RELATIVE 
                 }
            * @Param _page* : [int] #页数（从1开始） 
            * @Param _pageSize* : [int] #每页数量 
            */
            queryPic(_query, _page, _pageSize) { return post(url + '/queryPic', { query: JSON.stringify(_query), page: _page, pageSize: _pageSize }); },
            /**
            * 搜索视频图片稿件
            * @Param _query : #Object Post Query {
                     keyword:[string] #如果传如整形数字，则会转为搜索稿件ID 
                     appr:[int] #1=模糊 2=一般 3=精确，精确匹配，返回结果会变少很多但是更为精确 
                     postTypeCode:[int] #文字稿件=1  图片稿=2（默认） 视频稿件=3，不可同时搜索 
                     storeIds:[list:{int}]
                     keyInMain:[boolean]
                     keyInPic:[boolean]
                     typeId:[int]
                     addrId:[int]
                     author:[string]
                     eventFrom:[Date]
                     eventTo:[Date]
                     uploadFrom:[Date]
                     uploadTo:[Date]
                     order:[int] #上传逆序=0（默认） 事件时间逆序=1 上网时间逆序=2 入采编时间逆序=3 关键字关联度=4 
                     withNoLimit:[boolean] #是否强制取消上栏目限制 
                 }
            * @Param _page* : [int] #页数（从1开始） 
            * @Param _pageSize* : [int] #每页数量 
            */
            queryVideo(_query, _page, _pageSize) { return post(url + '/queryVideo', { query: JSON.stringify(_query), page: _page, pageSize: _pageSize }); },
            /**
            * 提取图片关键字，此接口是一个上传图片接口，上传限制50MB，如果成功，则内容会被存储在session中，query的时候加入keyByImage则可以使用
            */
            queryPicByUploader_URL() { return url + '/analyzePicByUploader'; },
        }
    },
    /* 内部信箱 */
    mail() {
        var url = prefixURL + '/mail'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('mail resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 发信
            * @Param _mailTargetId[int]
            * @Param _author[string]
            * @Param _title[string]
            * @Param _message : [string] #富文本 
            */
            sendMail(_mailTargetId, _author, _title, _message) { return post(url + '/sendMail', { mailTargetId: _mailTargetId, author: _author, title: _title, message: _message }); },
            /**
            * 收信目标列表
            */
            mailTargets() { return get(url + '/mailTargets'); },
        }
    },
    /* 地址 */
    addr() {
        var url = prefixURL + '/addr'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('addr resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 获得单个地址
                    */
                    one() { return get(url); },
                    /**
                    * 获得地址路径
                    */
                    path() { return get(url + '/path'); },
                    bgImage_URL() { return url + '/image'; },
                    /**
                    * 获得下级地址
                    */
                    downAddress() { return get(url + '/downAddress'); },
                }
            },
            /**
            * 地址树
            */
            tree() { return get(url + '/tree'); },
            /**
            * 获得根地址
            */
            root() { return get(url + '/root'); },
        }
    },
    /* 类型 */
    type() {
        var url = prefixURL + '/type'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('type resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 获得单个类型
                    */
                    one() { return get(url); },
                    /**
                    * 获得类型路径
                    */
                    path() { return get(url + '/path'); },
                    /**
                    * 获得下级类型
                    */
                    downTypes() { return get(url + '/downTypes'); },
                }
            },
            /**
            * 类型树(数据量大，不要频繁调用)
            */
            tree() { return get(url + '/tree'); },
            /**
            * 获得根类型
            */
            root() { return get(url + '/root'); },
        }
    },
    /* 投稿活动 */
    activityTopic() {
        var url = prefixURL + '/activityTopic'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('activityTopic resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 获得单一投稿活动
                    */
                    one() { return get(url); },
                    /**
                    * 活动的全部稿件
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    posts(_page, _pageSize) { return post(url + '/posts', { page: _page, pageSize: _pageSize }); },
                    /**
                    * 获得活动的图片，位置0-6
                    * @Param _position[int]
                    */
                    headPic_URL(_position) { return url + '/head' + '/' + _position; },
                }
            },
            /**
            * 获得全部可展示投稿活动
            */
            forShow() { return get(url + '/forShow'); },
            /**
            * 获得全部可投稿的投稿活动
            */
            forUpload() { return get(url + '/forUpload'); },
            checkVoter() { return get(url + '/checkVoter'); },
        }
    },
    /* 广告 */
    ad() {
        var url = prefixURL + '/ad'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('ad resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    one() { return get(url); },
                    /**
                    * 广告通栏图
                    */
                    image_URL() { return url + '/image'; },
                }
            },
            all() { return get(url); },
        }
    },
    /* 友情链接 */
    exchange() {
        var url = prefixURL + '/exchange'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('exchange resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    headPic_URL() { return url + '/headImage'; },
                }
            },
            all() { return get(url); },
        }
    },
    /* 固定栏目 */
    fixedTopic() {
        var url = prefixURL + '/fixedTopic'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('fixedTopic resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    one() { return get(url); },
                    headPic_URL() { return url + '/image'; },
                    /**
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    posts(_page, _pageSize) { return post(url + '/posts', { page: _page, pageSize: _pageSize }); },
                    /**
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    postsPager(_page, _pageSize) { return post(url + '/postsPager', { page: _page, pageSize: _pageSize }); },
                    subTopic() { return get(url + '/subTopic'); },
                    /**
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    postsWithoutPager(_page, _pageSize) { return post(url + '/postsWithoutPager', { page: _page, pageSize: _pageSize }); },
                }
            },
            /**
            * @Param _type*[int]
            */
            all(_type) { return get(url, { type: _type }); },
            types() { return get(url + '/types'); },
            /**
            * @Param _postId[int]
            */
            byPost(_postId) { return post(url + '/byPost', { postId: _postId }); },
        }
    },
    /* 通告 */
    notice() {
        var url = prefixURL + '/notice'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('notice resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    one() { return get(url); },
                    noticeImage_URL() { return url + '/image'; },
                }
            },
            /**
            * @Param _groupCode*[int]
            */
            allByGroup(_groupCode) { return get(url, { groupCode: _groupCode }); },
            /**
            * @Param _page*[int]
            * @Param _pageSize*[int]
            * @Param _groupCode*[int]
            */
            allByGroupWithPager(_page, _pageSize, _groupCode) { return post(url, { page: _page, pageSize: _pageSize, groupCode: _groupCode }); },
            groups() { return get(url + '/groups'); },
        }
    },
    /* 视觉江苏特殊固定栏目 */
    sjjsfixedTopic() {
        var url = prefixURL + '/sjjsfixedTopic'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('sjjsfixedTopic resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * @Param _range[int]
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    posts(_range, _page, _pageSize) { return post(url + '/' + _range + '/posts', { page: _page, pageSize: _pageSize }); },
                }
            },
            ranges() { return get(url + '/ranges'); },
            /**
            * @Param _range[int]
            */
            rangeDetails(_range) { return get(url + '/details' + '/' + _range); },
        }
    },
    /* 视频 */
    video() {
        var url = prefixURL + '/video'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('video resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 中版本视频下载
                    */
                    mVideoDownload_URL() { return url + '/mVideo'; },
                    /**
                    * 原始版本视频下载
                    */
                    oVideoDownload_URL() { return url + '/oVideo'; },
                    /**
                    * 海报
                    */
                    poster_URL() { return url + '/poster'; },
                    /**
                    * 检查小版本视频
                    */
                    scheck() { return get(url + '/s' + '/check'); },
                    /**
                    * 小版本视频
                    */
                    svideo_URL() { return url + '/s' + '/video'; },
                    /**
                    * 检查中版本视频
                    */
                    mcheck() { return get(url + '/m' + '/check'); },
                    /**
                    * 中版本视频
                    */
                    mvideo_URL() { return url + '/m' + '/video'; },
                }
            },
        }
    },
    /* 欢迎项 */
    welcome() {
        var url = prefixURL + '/welcome'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('welcome resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 根据group获得欢迎项，带分页
            * @Param _groupId[int]
            * @Param _page*[int]
            * @Param _pageSize*[int]
            */
            byGroup(_groupId, _page, _pageSize) { return post(url + '/byGroup' + '/' + _groupId, { page: _page, pageSize: _pageSize }); },
            /**
            * 根据group获得欢迎项，不带分页
            * @Param _groupId[int]
            */
            byGroupNoPager(_groupId) { return post(url + '/byGroupNoPager' + '/' + _groupId); },
        }
    },
    /* 排行榜 */
    statistic() {
        var url = prefixURL + '/statistic'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('statistic resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 注意此接口可能返回速度较慢
            * @Param _month[string]
            */
            userWorkStatistics(_month) { return post(url + '/user', { month: _month }); },
        }
    },
    /* 评审活动 */
    activityTopicVote() {
        var url = prefixURL + '/activityTopicVote'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('activityTopicVote resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * @Param _activityTopicVoteTargetId[int]
                    * @Param _value[int]
                    */
                    vote(_activityTopicVoteTargetId, _value) { return post(url + '/vote', { activityTopicVoteTargetId: _activityTopicVoteTargetId, value: _value }); },
                    finish() { return post(url + '/finish'); },
                    /**
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    voteInfo(_page, _pageSize) { return post(url + '/voteInfo', { page: _page, pageSize: _pageSize }); },
                    voteInfoUpdate() { return get(url + '/voteInfoUpdate'); },
                }
            },
        }
    },
    /* 展示用户 */
    userShow() {
        var url = prefixURL + '/userShow'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('userShow resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    oneWithoutType() { return get(url); },
                    /**
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    posts(_page, _pageSize) { return post(url + '/posts', { page: _page, pageSize: _pageSize }); },
                    oneWithPic() { return get(url + '/withPic'); },
                    headPic_URL() { return url + '/headImage'; },
                    /**
                    * @Param _page*[int]
                    * @Param _pageSize*[int]
                    */
                    postsByAuthor(_page, _pageSize) { return post(url + '/postsByAuthor', { page: _page, pageSize: _pageSize }); },
                }
            },
            tops() { return get(url + '/tops'); },
            allTypes() { return get(url + '/allTypes'); },
            /**
            * @Param _type[int]
            */
            byTypeCode(_type) { return get(url + '/byTypeCode' + '/' + _type); },
        }
    },
    /* 微信签名 */
    wx() {
        var url = prefixURL + '/wx'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('wx resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 进行微信签名认证
            * @Param _linkToSign[string]
            */
            sign(_linkToSign) { return post(url + '/sign', { linkToSign: _linkToSign }); },
        }
    },
    /* 日志 */
    clientlog() {
        var url = prefixURL + '/clientlog'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('clientlog resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            access() { return post(url + '/access'); },
        }
    },
    qr() {
        var url = prefixURL + '/qr'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('qr resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            checkQR() { return get(url + '/checkQR'); },
            getQR() { return get(url + '/image.jpg'); },
            /**
            * 微信客户端，扫描后通过此端口返回CODE，得到ScanLogin信息
            * @Param _code[string]
            */
            wxScanlogin(_code) { return post(url + '/wxScanlogin', { code: _code }); },
            /**
            * 微信客户端，确认后通过此端口返回Scan ID，确认登录
            * @Param _scanId[int]
            */
            wxScanloginConfirm(_scanId) { return post(url + '/wxScanloginConfirm', { scanId: _scanId }); },
        }
    },
    /* 订单 */
    purchaseOrder() {
        var url = prefixURL + '/purchaseOrder'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('purchaseOrder resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 查询order下的图片
                    */
                    listPicsOfOrder() { return get(url + '/pics'); },
                    /**
                    * 即时支付订单
                    */
                    payWithMoney() { return post(url + '/payWithMoney'); },
                    /**
                    * 余额支付订单
                    */
                    payWithCredit() { return post(url + '/payWithCredit'); },
                }
            },
            /**
            * 把一组图片ID送入购物车
            * @Param _picIds : [list:{int}] #图片Id数组 
            */
            toCart(_picIds) { return post(url + '/toCart', { picIds: JSON.stringify(_picIds) }); },
            status() { return get(url + '/status'); },
            /**
            * 把一组购物车图片生成订单，返回订单，对于不可重复下单的图片自动跳过
            * @Param _picCartId : [list:{int}] #图片购物车Id数组 
            * @Param _authMode[int]
            * @Param _orderInfo{
                     name:[string] #个人名字 
                     company:[string] #公司名 
                     activity:[string] #活动名 
                     startDate:[Date] #活动开始日期 
                     endDate:[Date] #活动结束日期 
                     date:[Date] #日期（报纸） 
                     mobile:[string] #手机号码 
                     paper:[string] #报纸名称 
                     page:[string] #报纸版面 
                     position:[string] #使用精确位置 
                     comment:[string] #备注 
                 }
            */
            toOrder(_picCartId, _authMode, _orderInfo) { return post(url + '/toOrder', { picCartId: JSON.stringify(_picCartId), authMode: _authMode, orderInfo: JSON.stringify(_orderInfo) }); },
            /**
            * 批量下载原图，注意传入的是picId的array，传入picIds = array 
            * @Param _picIds[list:{int}]
            */
            downloadBatch_URL(_picIds) { return url + '/download'; },
            /**
            * 把一组图片购物车删除
            * @Param _picCartId : [list:{int}] #图片购物车Id数组 
            */
            deleteCart(_picCartId) { return post(url + '/deleteCart', { picCartId: JSON.stringify(_picCartId) }); },
            /**
            * 扫码之后更新订单
            * @Param _orderId : [int] #订单ID 
            */
            checkOrder(_orderId) { return post(url + '/checkOrder', { orderId: _orderId }); },
            /**
            * 批量下载原图，注意传入的是orderPicId的array，传入orderPicIds = array 
            * @Param _orderPicIds[list:{int}]
            */
            downloadBatchByOrderPicId_URL(_orderPicIds) { return url + '/downloadOrderPic'; },
            /**
            * 余额直接购买支付前，此借口检查需要支付的金额，成功时返回可用余额，支付金额，支付张数，注意如果图片都已经购买，则三个值可能都为0
            * @Param _picIds : [list:{int}] #要购买的图片的id 
            */
            preCheckOrderPicsAndPayWithCredit(_picIds) { return post(url + '/preCheckOrderPicsAndPayWithCredit', { picIds: JSON.stringify(_picIds) }); },
        }
    },
    /* 见报图片 */
    paperOut() {
        var url = prefixURL + '/paperOut'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('paperOut resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 注意，from和to必须有，但是可以是空格， 如果from和to中有一个是不可解析的，则等同于不传时间，检索全部，必须两者都存在且符合yyyy-MM-dd格式，才会作为日期范围计算
            * @Param _from[string]
            * @Param _to[string]
            * @Param _page*[int]
            * @Param _pageSize*[int]
            */
            byTime(_from, _to, _page, _pageSize) { return post(url + '/byTime', { from: _from, to: _to, page: _page, pageSize: _pageSize }); },
        }
    },
    /* 授权方式 */
    authMode() {
        var url = prefixURL + '/authMode'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('authMode resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            fields() { return get(url + '/fields'); },
            /**
            * 获得所有的可购买模式
            */
            authModes() { return get(url + '/authModes'); },
        }
    },
    /* 附件上传 */
    attachment() {
        var url = prefixURL + '/attachment'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('attachment resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            /**
            * 附件上传--图片，只接受jpg和png
            */
            uploadImage_URL() { return url + '/image'; },
            /**
            * 附件上传--视频，只接受mp4，注意这里不会进行转码，所上传视频上传前必须确定是mp4并且可以在浏览器正常播放
            */
            uploadVideo_URL() { return url + '/video'; },
            /**
            * 附件上传--接受下载型文件，文件会按照原始文件名进行下载，注意不要使用不符合路径规范的文件名
            */
            uploadOther_URL() { return url + '/other'; },
        }
    },
    /* 招标公示，前台 */
    publicity() {
        var url = prefixURL + '/publicity'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('publicity resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 获得某公示的详情，包括所有细节
                    */
                    one() { return get(url); },
                }
            },
            /**
            * 列出所有可选的发布公示模式
            */
            modes() { return get(url + '/modes'); },
            /**
            * 列出所有可选的发布公示主体
            */
            owners() { return get(url + '/owners'); },
            /**
            * 搜索公示，带分页
            * @Param _page* : [int] #页码 
            * @Param _modes* : [list:{int}] #模式ID列表，用于匹配模式 
            * @Param _owners* : [list:{int}] #发布者ID列表，用于匹配发布者 
            * @Param _keyword* : [string] #关键字，用于匹配项目名字 
            * @Param _pageSize* : [int] #页长 
            */
            search(_page, _modes, _owners, _keyword, _pageSize) { return post(url + '/search', { page: _page, modes: JSON.stringify(_modes), owners: JSON.stringify(_owners), keyword: _keyword, pageSize: _pageSize }); },
        }
    },
    /* 输出，client端不使用，中心稿件分享机制用 */
    batchOutput() {
        var url = prefixURL + '/batchOutput'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('batchOutput resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    post() { return get(url); },
                }
            },
            /**
            * @Param _postId[int]
            */
            confirmOutput(_postId) { return get(url + '/confirmOutput' + '/' + _postId); },
        }
    },
    /* 输出 */
    temp() {
        var url = prefixURL + '/temp'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('temp resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                }
            },
            video_URL() { return url + '/{uuid}.mp4'; },
            image_URL() { return url + '/{uuid}.jpg'; },
        }
    },
    /* 分站 */
    node() {
        var url = prefixURL + '/node'
        return {
            id(resourceId) {
                if (!ifNumber(resourceId)) { console.log('node resourceId error'); } else {
                    url += '/' + resourceId
                }
                return {
                    /**
                    * 图
                    */
                    image_URL() { return url + '/image'; },
                }
            },
            /**
            * 所有激活的分站
            */
            actives() { return get(url + '/actives'); },
        }
    },
}
export default RD
