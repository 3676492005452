import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index.html",
  },
  {
    path: "/index.html",
    component: () => import("../views/Index.vue"),
  },
  // {
  //   path: "/welcome.html",
  //   component: () => import("../views/welcome.vue"),
  // },
  {
    path: '/login.html',
    component: () => import('../views/login.vue')
  },
  {
    path: '/register.html',
    component: () => import('../views/register.vue')
  },
  {
    path: '/forgetPassword.html',
    component: () => import('../views/forgetPassword.vue')
  },
  {
    path: "/search.html",
    component: () => import("../views/search.vue")
  },
  {
    path: "/catalog.html",
    component: () => import("../views/catalog.vue")
  },
  {
    path: "/videoCatalog.html",
    component: () => import("../views/videoCatalog.vue")
  },
  {
    path: "/fixtopic.html",
    component: () => import("../views/fixtopic.vue")
  },
  {
    path: "/userShow.html",
    component: () => import("../views/userShow.vue")
  },
  {
    path: "/userShowDetail.html",
    component: () => import("../views/userShowDetail.vue")
  },
  {
    path: "/announceDetail.html",
    component: () => import("../views/announceDetail.vue")
  },
  {
    path: "/userPostMore.html",
    component: () => import("../views/userPostMore.vue")
  },
  {
    path: '/local.html',
    component: () => import('../views/local.vue')
  },
  {
    path: '/post.html',
    component: () => import('../views/postDetail.vue')
  },
  {
    path: '/upPost.html',
    component: () => import('../views/upPost.vue')
  },
  {
    path: '/uploadVideo.html',
    component: () => import('../views/uploadVideo.vue')
  },
  {
    path: '/myPost.html',
    component: () => import('../views/myPost.vue')
  },
  {
    path: '/myInfo.html',
    component: () => import('../views/myInfo.vue')
  },
  {
    path: '/post.html',
    component: () => import('../views/postDetail.vue')
  },
  {
    path: '/newPosts.html',
    component: () => import('../views/newPosts.vue')
  },
  {
    path: '/alreadyBuy.html',
    component: () => import('../views/alreadyBuy.vue')
  },
  {
    path: '/shopCar.html',
    component: () => import('../views/shopCar.vue')
  },
  {
    path: '/myOrder.html',
    component: () => import('../views/myOrder.vue')
  },
  {
    path: "/updataInfo.html",
    component: () => import("../views/updataInfo.vue")
  },
  {
    path: "/updataPassWord.html",
    component: () => import("../views/updataPassWord.vue")
  },
  {
    path: "/bindMobile.html",
    component: () => import("../views/bindMobile.vue")
  },
  {
    path: '/bindShopChange.html',
    component: () => import('../views/bindShopChange.vue')
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});



// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// const originalReplace = VueRouter.prototype.replace
// VueRouter.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch(err => err)
// }


export default router;
