import RD from "@/api/RD";
export default {
    store: {
        count: 1,
        addrTree: [],
        typeTree: [],
        userInfo: {},
        logined: false,
        loginStatus: false,
        visitCount: 1, // 访问的数量
        activityList: [], // 活动赛事列表
    },

    /**
     * in this method, this is the basicFrame, vue component
     */
    initExtra() {
        console.log("init extra run");
        let thiz = this;
        // var prefixURL = "https://www.nxvisual.com/client/v_0.1";
        // var prefixURL = "https://hts.hz66.com/client/v_0.1";
        var prefixURL = "https://www.meimeiwujiang.com/client/v_0.1";

        // https://hts.hz66.com

        RD.setPreFix(prefixURL);
        RD.setErrorHandler(function (error) {
            console.log(error);
            return new Promise((resolve, reject) => {
                if (error.code && error.code < 10) {
                    thiz.$notify({ title: "发生错误", message: error.msg, type: 'error' });
                    thiz.$rtm.hideLoading();
                    resolve();
                } else {
                    reject();
                }
            });
        });
        RD.setLoadingStartAction(function () {
            console.log("showloading");
            thiz.$rtm.showLoading();
        });
        RD.setLoadingEndAction(function () {
            console.log("hideLoading");
            thiz.$rtm.hideLoading();
        });
    }
};
