import qs from "qs";
import rtmConfig from "./config";
var baseFrame;
let storeObject = {};
let loading = undefined;
let currentMessageWindow = undefined
let rtm = {
    message: {
        // info(message) {
        //   if (currentMessageWindow) { currentMessageWindow.close() }
        //   currentMessageWindow = baseFrame.$message({ type: 'info', message: message })
        // },
        error(message) {
            if (currentMessageWindow) { currentMessageWindow.close() }
            currentMessageWindow = baseFrame.$notify({ title: '提示', type: 'error', message: message, duration: 10000, position: 'top-right' })
        },
        alert(message, title) {
            baseFrame.$alert(message, title, { type: 'error', showClose: false })
        },
        notify(message) {
            if (currentMessageWindow) { currentMessageWindow.close() }
            currentMessageWindow = baseFrame.$notify({ title: '提示', message: message, type: 'warning', position: 'top-left' })
        },
        info(message) {
            if (currentMessageWindow) { currentMessageWindow.close() }
            currentMessageWindow = baseFrame.$notify({ title: '提示', type: 'info', message: message, position: 'top-left' })
        },
        warning(message) {
            if (currentMessageWindow) { currentMessageWindow.close() }
            currentMessageWindow = baseFrame.$notify({ title: '提示', type: 'warning', message: message, position: 'top-left' })
        },
    },
    /**
     * App.vue 初始化时调用
     * @param {*} base Appvue的this
     */
    config: rtmConfig,
    /**
     * 自定义存储
     */
    store: {},
    setBase(base) {
        baseFrame = base;
        if (this.config.initExtra) {
            this.config.initExtra.bind(baseFrame)();
        }
    },
    showLoading() {
        loading = baseFrame.$loading({
            lock: true,
            text: "加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
        });
    },
    hideLoading() {
        if (baseFrame) {
            loading.close();
        }
    },
    parseURL(url) {
        var result = {};
        if (url.indexOf("?") == -1) {
            result.item = url;
            result.index = url;
        } else {
            result.item = url.substr(0, url.indexOf("?"));
            var paramObject = qs.parse(url.substr(url.indexOf("?") + 1));
            var orderedParams = {};
            Object.keys(paramObject)
                .sort()
                .map(key => {
                    orderedParams[key] = paramObject[key];
                });
            result.paramObject = orderedParams;
            result.param = qs.stringify(orderedParams);
            result.index = result.item + "?" + result.param;
        }
        return result;
    }
};
export default {
    install(Vue) {
        Vue.prototype.$rtm = rtm;
        const strats = Vue.config.optionMergeStrategies;
        // use the same hook merging strategy for route hooks
        strats.activeGet = strats.activeLost = strats.beforeClose = function (
            parentVal,
            childVal
        ) {
            return childVal === undefined ? parentVal : childVal;
        };
        var newWatch = {};
        if (rtm.config.store) {
            storeObject = JSON.parse(JSON.stringify(rtm.config.store));
        }
        Object.keys(storeObject).forEach(key => {
            newWatch[key] = {
                handler: function () { },
                deep: true
            };
        });
        rtm.store = new Vue({
            data() {
                return storeObject;
            },
            watch: newWatch
        });
    }
};
